/* eslint no-param-reassign: "error" */

import { combineReducers } from 'redux';
import {
  RESTORE_PRODUCTS,
  RECEIVE_PRODUCTS,
  ADD_TO_CART,
} from '../../constants/ActionTypes';

const products = (state, action) => {
  switch (action.type) {
    case ADD_TO_CART:
      return {
        ...state,
        inventory: state.inventory - 1,
      };
    case RESTORE_PRODUCTS:
      return {
        ...state,
        inventory: state.inventory + 1,
      };
    default:
      return state;
  }
};

const byId = (state = {}, action) => {
  if (action.type === RECEIVE_PRODUCTS) {
    return {
      ...state,
      ...action.products.reduce((obj, product) => {
        obj[product.id] = product;
        return obj;
      }, {}),
    };
  }
  const { productId } = action;
  if (productId) {
    return {
      ...state,
      [productId]: products(state[productId], action),
    };
  }
  return state;
};

const visibleIds = (state = [], action) => {
  switch (action.type) {
    case RECEIVE_PRODUCTS:
      return action.products.map(product => product.id);
    default:
      return state;
  }
};

export default combineReducers({
  byId,
  visibleIds,
});

export const getProduct = (state, id) => state.byId[id];

export const getVisibleProducts = state => {
  if (state.visibleIds) {
    return state.visibleIds.map(id => getProduct(state, id));
  }
  return state.products.visibleIds.map(id => getProduct(state.products, id));
};
