import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  CHECKOUT_REQUEST,
  CHECKOUT_FAILURE,
} from '../../constants/ActionTypes';

const initialState = {
  addedIds: [],
  quantityById: {},
};

const addedIds = (state = initialState.addedIds, action, quantity) => {
  switch (action.type) {
    case REMOVE_FROM_CART:
      if (quantity[action.productId] === 1) {
        const index = state.indexOf(action.productId);
        state.splice(index, 1);
        return [...state];
      }
      return state;
    case ADD_TO_CART:
      if (state.indexOf(action.productId) !== -1) {
        return state;
      }
      return [...state, action.productId];
    default:
      return state;
  }
};

const quantityById = (state = initialState.quantityById, action) => {
  if (action.type === REMOVE_FROM_CART) {
    const { productId } = action;
    return {
      ...state,
      [productId]: (state[productId] || 0) - 1,
    };
  }
  if (action.type === ADD_TO_CART) {
    const { productId } = action;
    return {
      ...state,
      [productId]: (state[productId] || 0) + 1,
    };
  }

  return state;
};

export const getQuantity = (state, productId) =>
  state.quantityById[productId] || 0;

export const getAddedIds = state => state.addedIds;

const cart = (state = initialState, action) => {
  switch (action.type) {
    case CHECKOUT_REQUEST:
      return initialState;
    case CHECKOUT_FAILURE:
      return action.cart;
    default:
      return {
        addedIds: addedIds(state.addedIds, action, state.quantityById),
        quantityById: quantityById(state.quantityById, action),
      };
  }
};

export default cart;
