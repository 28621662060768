/* eslint-disable import/prefer-default-export */

export const SET_RUNTIME_VARIABLE = 'SET_RUNTIME_VARIABLE';
export const SET_DESING_ID = 26144;
export const SET_LOCALE_START = 'SET_LOCALE_START';
export const SET_LOCALE_SUCCESS = 'SET_LOCALE_SUCCESS';
export const SET_LOCALE_ERROR = 'SET_LOCALE_ERROR';
export const AVAILABLE_LOCALES = ['en' /* 'cs'  'uk' */];
export const AVAILABLE_CURRENCY = [
  {
    code: 'EUR',
    symbol: '€',
  },
  {
    code: 'USD',
    symbol: '$',
  },
  {
    code: 'UAH',
    symbol: '₴',
  },
  {
    code: 'PLN',
    symbol: '',
  },
  {
    code: 'CZK',
    symbol: '',
  },
];
export const CURRENCY = {
  CURRENT_CURRENCY: 'CURRENCY_CURRENT_CURRENCY',
  SET: 'CURRENCY_SET',
};
export const HISTORY = {
  CURRENT_PATH_SET: 'HISTORY_CURRENT_PATH_SET',
  CURRENT_ROUTE_NAME_SET: 'HISTORY_CURRENT_ROUTE_NAME_SET',
  PREVIOUS_ROUTE_NAME_SET: 'HISTORY_PREVIOUS_ROUTE_NAME_SET',
  PREVIOUS_PATH_SET: 'HISTORY_PREVIOUS_PATH_SET',
  NEXT_SEARCH_ITEM_INDEX: 'HISTORY_NEXT_SEARCH_ITEM_INDEX',
};
export const LOADING = {
  START: 'LOADING_STARTED',
  DONE: 'LOADING_DONE',
  SET_INITIAL: 'LOADING_SET_INITIAL',
};
const basePath = '/api';

export const API = {
  CHECKOUT: `${basePath}/checkout/url/`,
  TOKEN: `${basePath}/checkout/token/`,
  SIGNATURE: '/signature',
  SAVE_CART: '/save_cart',
};

export const GOOGLE_MAP_KEY = 'AIzaSyC6-VXnnz5Fl5LYtTeZKH9l7fdngEcrX6M';
