import { LOADING } from '../constants';

const initialState = {
  start: false,
  done: false,
};

const loading = (state = initialState, action) => {
  switch (action.type) {
    case LOADING.START:
      return {
        ...state,
        start: true,
        done: false,
      };
    case LOADING.DONE:
      return {
        ...state,
        start: false,
        done: true,
      };
    case LOADING.SET_INITIAL:
      return initialState;
    default:
      return state;
  }
};

export default loading;
