import { CURRENCY } from '../constants';

const initialState = {
  currentCurrency: null,
};

const currency = (state = initialState, action) => {
  switch (action.type) {
    case CURRENCY.SET:
      return { ...state, currentCurrency: action.payload };

    default:
      return state;
  }
};

export default currency;
