import { createAction } from 'redux-actions';
import { LOADING } from '../constants';

export const loadingStart = createAction(LOADING.START);
export const loadingDone = createAction(LOADING.DONE);
export const loadingSetInitial = createAction(LOADING.SET_INITIAL);

export const loadingDoneAndSetInitial = () => dispatch => {
  dispatch(loadingDone());
  dispatch(loadingSetInitial());
};
