import { HISTORY } from '../constants';

const initialState = {
  previousPath: null,
  currentPath: null,
  previousRouteName: null,
  currentRouteName: null,
  nextSearchItemIndex: null,
};

const history = (state = initialState, action) => {
  switch (action.type) {
    case HISTORY.CURRENT_PATH_SET:
      return { ...state, currentPath: action.payload };

    case HISTORY.PREVIOUS_ROUTE_NAME_SET:
      return { ...state, previousRouteName: action.payload };

    case HISTORY.CURRENT_ROUTE_NAME_SET:
      return { ...state, currentRouteName: action.payload };

    case HISTORY.PREVIOUS_PATH_SET:
      return { ...state, previousPath: action.payload };

    case HISTORY.NEXT_SEARCH_ITEM_INDEX:
      return { ...state, nextSearchItemIndex: action.payload };

    default:
      return state;
  }
};

export default history;
