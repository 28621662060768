import { combineReducers } from 'redux';
import user from './user';
import runtime from './runtime';
import intl from './intl';
import history from './history';
import loading from './loading';
import currency from './currency';
import cart, * as fromCart from './cart/cart';
import products, * as fromProducts from './cart/products';

const getAddedIds = state => fromCart.getAddedIds(state.cart);
const getQuantity = (state, id) => fromCart.getQuantity(state.cart, id);
const getProduct = (state, id) => fromProducts.getProduct(state.products, id);

export const getTotal = state =>
  getAddedIds(state)
    .reduce(
      (total, id) =>
        total + getProduct(state, id).price * getQuantity(state, id),
      0,
    )
    .toFixed(2);

export const getCartTotal = state =>
  getAddedIds(state).reduce((total, id) => total + getQuantity(state, id), 0);

export const getCartProducts = state =>
  getAddedIds(state).map(id => ({
    ...getProduct(state, id),
    quantity: getQuantity(state, id),
  }));

export default combineReducers({
  user,
  cart,
  products,
  runtime,
  loading,
  intl,
  history,
  currency,
});
