/* eslint-disable import/prefer-default-export */

import { createAction } from 'redux-actions';
import { HISTORY } from '../constants';

export const setPreviousPath = path => ({
  type: HISTORY.PREVIOUS_PATH_SET,
  payload: path,
});

export const setCurrentRouteName = name => ({
  type: HISTORY.CURRENT_ROUTE_NAME_SET,
  payload: name,
});

export const setPreviousRouteName = name => ({
  type: HISTORY.PREVIOUS_ROUTE_NAME_SET,
  payload: name,
});

export const setCurrentPath = path => ({
  type: HISTORY.CURRENT_PATH_SET,
  payload: path,
});

export const setNextSearchItemIndex = createAction(
  HISTORY.NEXT_SEARCH_ITEM_INDEX,
);
