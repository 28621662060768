import React from 'react';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import { Provider as ReduxProvider } from 'react-redux';
import { ApolloProvider } from 'react-apollo';

const Context = React.createContext();

const ContextType = {
  insertCss: PropTypes.func.isRequired,
  fetch: PropTypes.func.isRequired,
  pathname: PropTypes.string.isRequired,
  query: PropTypes.object,
  ...ReduxProvider.childContextTypes,
  client: PropTypes.object.isRequired,
  intl: IntlProvider.childContextTypes.intl,
  locale: PropTypes.string,
};

export const ContextConsumer = Context.Consumer;

class App extends React.PureComponent {
  static propTypes = {
    context: PropTypes.shape(ContextType).isRequired,
    children: PropTypes.element.isRequired,
  };

  static childContextTypes = ContextType;

  getChildContext() {
    return this.props.context;
  }

  render() {
    // Here, we are at universe level, sure? ;-)
    const { client, ...context } = this.props.context;
    // NOTE: If you need to add or modify header, footer etc. of the app,
    // please do that inside the Layout component.
    return (
      <Context.Provider value={context}>
        <ApolloProvider client={client}>{this.props.children}</ApolloProvider>
      </Context.Provider>
    );
  }
}

export default App;
