/* eslint-disable   '/login',
  '/register',global-require */
import queryString from 'query-string';
import { AVAILABLE_LOCALES } from '../constants/';
import { loadingStart, loadingDoneAndSetInitial } from '../actions/loading';
import {
  setPreviousPath,
  setCurrentPath,
  setCurrentRouteName,
  setPreviousRouteName,
} from '../actions/history';

const routes = {
  path: [...AVAILABLE_LOCALES.map(locale => `/${locale}`), '/'],

  // Keep in mind, routes are evaluated in order
  children: [
    {
      path: '',
      load: () => import(/* webpackChunkName: 'home' */ './home'),
    },
    {
      path: '/customize-checkout',
      load: () => import(/* webpackChunkName: 'customize' */ './customize'),
    },
    {
      path: '/checkout',
      load: () => import(/* webpackChunkName: 'checkout' */ './checkout'),
    },
    {
      path: '/widget/:type',
      load: () => import(/* webpackChunkName: 'widget' */ './widget'),
    },
    {
      path: '/thank-you',
      load: () => import(/* webpackChunkName: 'thank-you' */ './thank-you'),
    },

    // Wildcard routes, e.g. { path: '(.*)', ... } (must go last)
    {
      path: '(.*)',
      load: () => import(/* webpackChunkName: 'not-found' */ './not-found'),
    },
  ],

  async action({ next, store, pathname, query }) {
    store.dispatch(loadingStart());
    try {
      // Execute each child route until one of them return the result
      const route = await next();
      route.title = `${route.title || 'E-Shop'}`;
      route.description = `${route.description || ''}`;
      route.pathname = pathname || '';
      route.query = query || {};

      // History
      const previousPath = store.getState().history.currentPath;
      const previousRouteName = store.getState().history.currentRouteName;
      const stringifierQuery = queryString.stringify(query);
      const newPath = `${route.pathname}${stringifierQuery &&
        `?${stringifierQuery}`}`;

      if (newPath !== previousPath) {
        if (previousPath) {
          store.dispatch(setPreviousPath(previousPath));
        }

        store.dispatch(setCurrentPath(newPath));
      }
      store.dispatch(setPreviousRouteName(previousRouteName || null));
      store.dispatch(setCurrentRouteName(route.routeName || null));
      return route;
    } catch (err) {
      throw err;
    } finally {
      store.dispatch(loadingDoneAndSetInitial());
    }
  },
};

// The error page is available by permanent url for development mode
if (__DEV__) {
  routes.children.unshift({
    path: '/error',
    action: require('./error').default,
  });
}

export default routes;
