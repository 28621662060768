import { AVAILABLE_LOCALES } from '../constants/';

const translateBasic = (string, messages) =>
  typeof string === 'string' && messages[string] ? messages[string] : string;

export const curriedTranslate = messages => string =>
  translateBasic.call(null, string, messages);

export const removeLocaleFromHref = href => {
  const regexp = new RegExp(`/(${AVAILABLE_LOCALES.join('|')})/`);
  return href.replace(regexp, '/');
};

export const toLocalized = (href, locale) => {
  if (
    !href ||
    !locale ||
    AVAILABLE_LOCALES.find(l => href.startsWith(`/${l}/`))
  ) {
    return href ? href.replace(/\/\//g, '/') : href;
  }

  return href.startsWith('/')
    ? `/${locale}${href}`.replace(/\/\//g, '/')
    : href.replace(/\/\//g, '/');
};
export const injectLocationIntoHref = (href, locationSlug) =>
  `${href}/${locationSlug}/`.replace(/\/\//g, '/');

export const isLocalizedUrl = href =>
  AVAILABLE_LOCALES.find(l => href.startsWith(`/${l}`));
